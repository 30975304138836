export default {
    log_in: {
        es: 'Iniciar Sesión',
        us: 'Log In',
        fr: 'Se connecter',
        pt: 'Iniciar sessão'
    },
    user: {
        es: 'Usuario',
        us: 'User',
        fr: 'Utilisateur',
        pt: 'Usuário'
    },
    password: {
        es: 'Contraseña',
        us: 'Password',
        fr: 'Mot de passe',
        pt: 'Senha'
    },
    enter: {
        es: 'Entrar',
        us: 'Enter',
        fr: 'Entrer',
        pt: 'Entrar'
    },
    latest_10_vehicles: {
        es: 'Últimos 10 vehículos',
        us: 'Latest 10 vehicles',
        fr: 'Derniers 10 véhicules',
        pt: 'Últimos 10 veículos'
    },
    bulletins: {
        es: 'Boletines',
        us: 'Bulletins',
        fr: 'Bulletins',
        pt: 'Boletins'
    },
    learning: {
        es: 'Formación',
        us: 'Learning',
        fr: 'Formation',
        pt: 'Formação'
    },
    processes: {
        es: 'Procesos',
        us: 'Processes',
        fr: 'Processus',
        pt: 'Processos'
    },
    our_brands: {
        es: 'Nuestras marcas',
        us: 'Our brands',
        fr: 'Nos marques',
        pt: 'Nossas marcas'
    },
    search_code_by_dtc: {
        es: 'Buscar código por DTC',
        us: 'Search code by DTC',
        fr: 'Rechercher le code par DTC',
        pt: 'Buscar código por DTC'
    },
    model: {
        es: 'Modelo',
        us: 'Model',
        fr: 'Modèle',
        pt: 'Modelo'
    },
    from: {
        es: 'Desde',
        us: 'From',
        fr: 'De',
        pt: 'Desde'
    },
    to: {
        es: 'Hasta',
        us: 'To',
        fr: 'À',
        pt: 'Até'
    },
    type: {
        es: 'Tipo',
        us: 'Type',
        fr: 'Type',
        pt: 'Tipo'
    },
    engine: {
        es: 'Motor',
        us: 'Engine',
        fr: 'Moteur',
        pt: 'Motor'
    },
    power: {
        es: 'Potencia',
        us: 'Power',
        fr: 'Puissance',
        pt: 'Potência'
    },
    displacement: {
        es: 'Cilindrada',
        us: 'Displacement',
        fr: 'Cylindrée',
        pt: 'Cilindrada'
    },
    sensors: {
        es: 'Sensores',
        us: 'Sensors',
        fr: 'Capteurs',
        pt: 'Sensores'
    },
    actuators: {
        es: 'Actuadores',
        us: 'Actuators',
        fr: 'Actionneurs',
        pt: 'Atuadores'
    },
    sensors_potentiometers_pickups: {
        es: 'Captadores, sensores, potenciómetros...',
        us: 'Sensors, potentiometers, pickups...',
        fr: 'Capteurs, potentiomètres, pickups...',
        pt: 'Captadores, potenciómetros, sensores...'
    },
    solenoid_valves_servomotors_regulators_injectors: {
        es: 'Electroválvulas, servomotores, reguladores, inyectores...',
        us: 'Solenoid valves, servomotors, regulators, injectors...',
        fr: 'Électrovannes, servomoteurs, régulateurs, injecteurs...',
        pt: 'Válvulas solenoides, servomotores, reguladores, injetores...'
    },
    view_processes: {
        es: 'Ver procesos',
        us: 'View processes',
        fr: 'Voir les processus',
        pt: 'Ver processos'
    },
    view_syllabus: {
        es: 'Ver temarios',
        us: 'View syllabus',
        fr: 'Voir le programme',
        pt: 'Ver programa'
    },
    code_search: {
        es: 'Búsqueda del código',
        us: 'Code search',
        fr: 'Recherche de code',
        pt: 'Busca de código'
    },
    description: {
        es: 'Descripción',
        us: 'Description',
        fr: 'Description',
        pt: 'Descrição'
    },
    reason: {
        es: 'Razón',
        us: 'Reason',
        fr: 'Raison',
        pt: 'Razão'
    },
    issue: {
        es: 'Problema',
        us: 'Issue',
        fr: 'Problème',
        pt: 'Problema'
    },
    cause: {
        es: 'Causa',
        us: 'Cause',
        fr: 'Cause',
        pt: 'Causa'
    },
    solution: {
        es: 'Solución',
        us: 'Solution',
        fr: 'Solution',
        pt: 'Solução'
    },
    send_suggestion: {
        es: 'Enviar Sugerencia',
        us: 'Send suggestion',
        fr: 'Envoyer une suggestion',
        pt: 'Enviar sugestão'
    },
    symptom: {
        es: 'Síntoma',
        us: 'Symptom',
        fr: 'Symptôme',
        pt: 'Sintoma'
    },
    disclaimer_clause: {
        es: 'Cláusula de exención de responsabilidad',
        us: 'Disclaimer clause',
        fr: 'Clause de non-responsabilité',
        pt: 'Cláusula de isenção de responsabilidade'
    },
    login_error: {
        es: 'Usuario o contraseña incorrectos',
        us: 'Incorrect username or password',
        fr: 'Nom d\'utilisateur ou mot de passe incorrect',
        pt: 'Nome de usuário ou senha incorretos',
    },
    select_bulletin: {
        es: 'Seleccione un boletín',
        us: 'Select a bulletin',
        fr: 'Sélectionnez une bulletin',
        pt: 'Selecione um boletim',
    },
    suggestion: {
        es: 'Sugerencia',
        us: 'Suggestion',
        fr: 'Suggestion',
        pt: 'Sugestão',
    },
    system_courses: {
        es: 'Cursos por sistema',
        us: 'System courses',
        fr: 'Cours par système',
        pt: 'Cursos por sistema',
    },
    vehicle_courses: {
        es: 'Cursos por vehículo',
        us: 'Vehicle courses',
        fr: 'Cours par véhicule',
        pt: 'Cursos por veículo',
    },
    management_courses: {
        es: 'Cursos de gestión',
        us: 'Management courses',
        fr: 'Cours de gestion',
        pt: 'Cursos de gestão',
    },
    thank_you: {
        es: '¡Gracias!',
        us: 'Thank you!',
        fr: 'Merci!',
        pt: 'Obrigado!',
    },
    manufacturer: {
        es: 'Fabricante',
        us: 'Manufacturer',
        fr: 'Fabricant',
        pt: 'Fabricante',
    },
    warning: {
        es: 'Advertencia',
        us: 'Warning',
        fr: 'Avertissement',
        pt: 'Aviso',
    },
    ia_warning: {
        es: 'La inteligencia artificial proporcionará respuestas que pueden resultar útiles, sin embargo estas <b>no están supervisadas</b> y pueden contener errores o información incorrecta. Por ello es importante tenerlo en consideración a la hora de emprender acciones a partir de la respuesta. Ante cualquier duda, te recomendamos consultar a nuestros técnicos de <b>NextAssist</b>.',
        us: 'Artificial intelligence will provide answers that may be useful, however, these are <b>not supervised</b> and may contain errors or incorrect information. Therefore, it is important to take this into consideration when taking actions based on the response. If in doubt, we recommend consulting our <b>NextAssist</b> technicians.',
        fr: 'L’intelligence artificielle fournira des réponses qui peuvent s’avérer utiles, cependant, celles-ci <b>ne sont pas supervisées</b> et peuvent contenir des erreurs ou des informations incorrectes. Il est donc important de prendre cela en considération lors de la prise de décisions basées sur la réponse. En cas de doute, nous vous recommandons de consulter nos techniciens <b>NextAssist</b>.',
        pt: 'A inteligência artificial fornecerá respostas que podem ser úteis, no entanto, estas <b>não são supervisionadas</b> e podem conter erros ou informações incorretas. Portanto, é importante levar isso em consideração ao tomar ações com base na resposta. Em caso de dúvida, recomendamos que consulte nossos técnicos da <b>NextAssist</b>.',
    },
    inquiry: {
        es: 'Consulta',
        us: 'Inquiry',
        fr: 'Consultation',
        pt: 'Consulta',
    },
    make_inquiry: {
        es: 'Realizar Consulta',
        us: 'Make an Inquiry',
        fr: 'Réaliser une Consultation',
        pt: 'Realizar Consulta',
    },
    response: {
        es: 'Respuesta',
        us: 'Response',
        fr: 'Réponse',
        pt: 'Resposta',
    },






}